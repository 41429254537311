import React from 'react'
import Layout from '../components/Layout'
import { PageHeader } from '../components/PageHeader'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

const NotFoundPage = props => {
  const { data, location } = props
  const { wordpressWpSettings } = data
  const { title } = wordpressWpSettings
  return (
    <Layout location={location} heroTheme="White">
      <SEO title={`404 | ${title}`} />
      <PageHeader
        headerTitle="404 Not Found"
        headerSubTitle={`Page not found.`}
        compact={false}
        location={location}
      />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    wordpressWpSettings {
      title
    }
  }
`
